



































































import {Component, Vue, Prop} from 'vue-property-decorator';
import store from '@/services/store';

@Component({
    name: 'c-income-modal',
    components: {}
})
export default class CClarifyIncomeModal extends Vue {
    @Prop({
        required: true,
        default: false
    })
    private show!: boolean;

    private showModal = false;

    @Prop({
            required: true,
            default: 0
        }
    )
    private budget_level!: number;
    // private budgetLevel: number[] = [];

    // -----kat
    private katBase: any[] = [];
    private curKat: any | null = null;

    private get katDict(): any[] {
        const result: any[] = [];
        for (const el of this.katBase) {
            result.push(this.setNameLang(el, 'kat'));
        }
        return result;
    }

    // --- cls
    private clsBase: any[] = [];
    private curCls: any | null = null;

    private get clsDict(): any[] {
        const result: any[] = [];
        for (const el of this.clsBase) {
            result.push(this.setNameLang(el, 'cls'));
        }
        return result;
    }

    // ----pcl
    private pclBase: any[] = [];
    private curPcl: any | null = null;

    private get pclDict(): any[] {
        const result: any[] = [];
        for (const el of this.pclBase) {
            result.push(this.setNameLang(el, 'pcl'));
        }
        return result;
    }

    // --- spf
    private spfBase: any[] = [];
    private curSpf: any | null = null;

    private get spfDict(): any[] {
        const result: any[] = [];
        for (const el of this.spfBase) {
            result.push(this.setNameLang(el, 'spf'));
        }
        return result;
    }

    // ----


    private get usrId() {
        if (store.state.user.sub === undefined) {
            return null;
        }
        return store.state.user.sub;
    }

    private async loadDicts(kat: number | null, cls: number | null, pcl: number | null) {
        const params = {kat: kat, cls: cls, pcl: pcl};
        let response: any = [];
        try {
            response = await fetch(`/api-py/get-ebk-doh-lst/${encodeURI(JSON.stringify(params))}`);
            if (response.status === 200) {
                response = await response.json();
            } else {
                this.makeToast('danger', 'get-ebk-doh-lst', `${response.status} - ${response.statusText}`);
                return [];
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка get-ebk-doh-lst', (error as Error).toString());
            return [];
        }
        for (const el of response) {
            el.cls = el.clss;
            delete el.clss;
        }
        return response;
    }

    private async loadKat() {
        this.curKat = null;
        this.curCls = null;
        this.curPcl = null;
        this.curSpf = null;
        this.katBase = await this.loadDicts(null, null, null);
        if (this.katBase.length) {
            this.curKat = this.setNameLang(this.katBase[0], 'kat');
        }
        this.loadCls();
    }

    private async loadCls() {
        this.clsBase = [];
        this.curCls = null;
        this.curPcl = null;
        this.curSpf = null;
        if (!this.curKat) {
            return;
        }
        this.clsBase = await this.loadDicts(this.curKat.kat, null, null);
        if (this.clsBase.length) {
            this.curCls = this.setNameLang(this.clsBase[0], 'cls');
        }
        this.loadPcl();
    }

    private async loadPcl() {
        this.pclBase = [];
        this.curPcl = null;
        this.curSpf = null;
        if (!this.curCls) {
            return;
        }
        this.pclBase = await this.loadDicts(this.curKat.kat, this.curCls.cls, null);
        if (this.pclBase.length) {
            this.curPcl = this.setNameLang(this.pclBase[0], 'pcl');
        }
        this.loadSpf();
    }

    private async loadSpf() {
        this.spfBase = [];
        this.curSpf = null;
        if (!this.curPcl) {
            return;
        }
        const result = await this.loadDicts(this.curKat.kat, this.curCls.cls, this.curPcl.pcl);
        for (const el of result) {
            if (typeof el.budget_levels === "string") {
               if (el.budget_levels.includes(this.budget_level))
                this.spfBase.push(el);
            }
        }
        // this.spfBase = result;
        if (this.spfDict.length) {
            this.curSpf = this.setNameLang(this.spfBase[0], 'spf');
        }
    }

    // private async loadBudgetLevel() {
    //     this.budgetLevel = [];
    //     if (this.usrId === null) {
    //         return;
    //     }
    //     let result = [];
    //     try {
    //         result = await fetch(encodeURI('/api-py/get-user-budget-level/' + this.usrId)).then(response => response.json());
    //     } catch (error) {
    //         this.makeToast('danger', 'Ошибка загрузки уровня бюджета', (error as Error).toString());
    //         result = [];
    //         return;
    //     }
    //     if (!Array.isArray(result)) {
    //         return;
    //     }
    //
    //     for (const el of result) {
    //         // eslint-disable-next-line @typescript-eslint/camelcase
    //         const indx = el.budget_level.lastIndexOf('_');
    //         if (indx >= 0) {
    //             this.budgetLevel.push(parseInt(el.budget_level.substr(indx + 1)));
    //         }
    //     }
    //     if (this.budgetLevel.length === 0) {
    //         this.makeToast('warning', 'Внимание!', 'Нет доступных уровней бюджета у пользователя!');
    //     }
    // }

    private addClk() {
        const result = {curKat: this.curKat, curCls: this.curCls, curPcl: this.curPcl, curSpf: this.curSpf};
        this.$emit('addClk', result);
        this.showModal = false;
    }

    private async mounted() {
        // await this.loadBudgetLevel();
        await this.loadKat();
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        this.$watch('show', function () {
            if (that.show) {
                that.showModal = that.show;
            }
            that.$emit('shown');
        });
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) {
            txt = obj.name_ru;
        }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = obj[codeName] + ' - ' + txt;
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }
}
