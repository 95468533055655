<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджетное планирование</span>
        </div>
        <div class="section-subtitle"><i class="icon icon-grid"></i>Уточнение бюджета</div>
        <b-tabs class="w-100" v-model="active_tab">
            <b-tab title="Расходы">
                <cost-clarify-spf :year="year"
                                  :obl="obl"
                                  :reg="region"
                                  :variant="variant"
                                  :list-reg="listReg"
                                  :list-variants="listVariants"
                                  @change_filter="changeChildFilter"
                ></cost-clarify-spf>
            </b-tab>
            <b-tab title="Доходы" lazy>
                <template>
                    <income-clarify :year="year"
                                    :obl="obl"
                                    :reg="region"
                                    :variant="variant"
                                    :list-reg="listReg"
                                    :list-variants="listVariants"
                                    @change_filter="changeChildFilter"
                    ></income-clarify>
                </template>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import CostClarifySpf from '@/modules/budget/budget-correct/CostClarifySpf.vue';
import IncomeClarify from '@/modules/budget/budget-correct/IncomeClarify.vue';
import {Ax} from '@/utils';
import {getActualVariant, loadVariantsByRegionCode} from '@/modules/budget/budgetVariantService';
import {CUR_YEAR} from '@/modules/budget-request/components/js/budgetCurYear';

export default {
    // name: 'BudgetCorrect',
    components: {IncomeClarify, CostClarifySpf},
    data() {
        return {
            year: CUR_YEAR + 1,
            obl: null,
            region: null,
            variant: null,
            active_tab: 0,
            selectedABP: {id: 0, child: []},
            selectedPRG: {id: 0, child: []},
            selectedPPR: {id: 0},
            listReg: [],
            listVariants: []
        };
    },

    async mounted() {
        this.year = CUR_YEAR + 1;
        await this.getObl();
        await this.loadRegions();
        const queryString = new URL(document.location.href.replace("#", "")).search;
        const params = new URLSearchParams(queryString);
        const tab = params.get("tab");
        if (tab === "1") this.active_tab = 0;
        if (tab === "2") this.active_tab = 1;
    },

    methods: {
        async changeChildFilter(filter) {
            this.variant = filter.variant;
            this.year = filter.year;
            this.region = filter.region;
            await this.loadVariantsList();
        },

        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + this.$store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                        // this.region = json.region;
                    });
                await getActualVariant(this.region).then(data => {
                    this.variant = data;
                });
                await this.loadVariantsList();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },

        is_available(module) {
            return this.$store.getters.user.userModules.find(value => value.modules === module)
        },

        async loadVariantsList() {
            try {
                await loadVariantsByRegionCode(this.region).then(data => {
                    this.listVariants = data;
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadVariantsList', error.toString());
            }
        },

        async loadRegions() { // справочник регионов
            try {
                const response = await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + this.$store.getters.user_uuid);
                this.listReg = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
        },
        changeParams(data) {
            this.selectedABP = data.selABP;
            this.selectedPRG = data.selPRG;
            this.selectedPPR = data.selPPR;
        },
        infoCostForm(info_id) {
            const that = this;
            Ax(
                {
                    url: '/api-py/get-info/' + info_id, //  Тело файла
                    method: 'POST',
                    data: null,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let file = '';
                    if (info_id === 5) {
                        file = 'РП Корректировка расходной части бюджета';
                    }
                    if (info_id === 6) {
                        file = 'РП Корректировка доходной части бюджета';
                    }
                    link.setAttribute('download', file + '.pdf');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },
        // формирование отчета
        downloadToExcel() {
            const that = this;
            Ax(
                {
                    url: '/api-py/correct-download/1/' + that.region + '/' + that.variant + '/' + that.year, //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Отчет_по_корректировке_обл_бюджета.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },
        // формирование отчета
        downloadToExcelDoh() {
            const that = this;
            Ax(
                {
                    url: '/api-py/correct-download/2/' + that.region + '/' + that.variant + '/' + that.year, //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Отчет_по_корректировке_обл_бюджета.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },

        downloadToExcelAll() {
            const that = this;
            Ax(
                {
                    url: '/api-py/correct-download/3/' + that.region + '/' + that.variant + '/' + that.year, //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Отчет_по_корректировке_обл_бюджета.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        } // сообщение
    }
};
</script>
<style>
/*.is-hidden {*/
/*    display: none !important;*/
/*}*/
</style>