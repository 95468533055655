export async function getActualVariant(regionCode: string) {
    return getFromBackActualVariant(regionCode);
}

export interface IVariantInfo {
    budget_request_form_total: number;
    budget_cost_data: number;
    budget_income_data: number;
}

export interface IVariant {
    _showDetails: boolean;
    id: number;
    name_ru: string;
    name_kk?: any;
    name_en?: any;
    year: number;
    data_type?: any;
    attribute?: any;
    status?: any;
    basis_ru?: any;
    basis_kk?: any;
    date_time?: any;
    date_start?: any;
    date_ueb?: any;
    date_abp?: any;
    variant_uuid: string;
    is_deleted?: any;
    region_code?: string;
    prev_variant?: string;
    variant_info?: IVariantInfo;
    user_name?: string;
}

export interface IVariantBase {
    id: number;
    name_ru: string;
    name_kk?: any;
    name_en?: any;
    year: number;
    data_type?: any;
    attribute?: any;
    status?: any;
    basis_ru?: any;
    basis_kk?: any;
    date_time?: any;
    date_start?: any;
    date_ueb?: any;
    date_abp?: any;
    variant_uuid: string;
    is_deleted?: any;
    region_code?: string;
    prev_variant?: string;
    update_date?: any;
    user_name?: string;
    disabled: boolean;
}

async function getFromBackActualVariant(region_code: string) {
    let result = '';
    try {
        await fetch('/api-py/get-actual-variant/' + region_code)
            .then(response => response.json())
            .then(json => {
                result = json;
            });
    } catch (error) {
        console.log('Ошибка запроса getFromBackActualVariant', error);
    }
    return result;
}

export async function loadVariants() {
    let result: IVariant [] = [];
    try {
        await fetch('/api-py/get-variants')
            .then(response => response.json())
            .then(json => {
                result = json;
            });
    } catch (err) {
        throw 'Ошибка запроса вариантов';
    }
    return result;
}

export async function loadVariantsByRegionCode(region: string) {
    let result: IVariant [] = [];
    try {
        await fetch('/api-py/get-variants-by-region/' + region)
            .then(response => response.json())
            .then(json => {
                result = json;
            });
    } catch (err) {
        throw 'Ошибка запроса вариантов';
    }
    return result;
}

export async function loadVariantByUiid(uiid: string)  {
    let result!: IVariant;
    try {
        const response = await fetch('/api-py/get-variant-by-uiid/' + uiid)
        result = await response.json();
        return result;
    } catch (err) {
        throw 'Ошибка запроса вариантов';
    }
    return result;
}

export async function loadVariantsByUserId(user_id: string) {
    let result: IVariant [] = [];
    try {
        await fetch('/api-py/get-variants-by-userid/' + user_id)
            .then(response => response.json())
            .then(json => {
                result = json;
            });
    } catch (err) {
        throw 'Ошибка запроса вариантов';
    }
    return result;
}

export async function loadPrevVariantsByRegionCode(region: string, variant_uuid: string) {
    let res: string = '';
    let variants: IVariant[] = []
    await loadVariantsByRegionCode(region).then(data => {
        variants = data
    })
    for (const v of variants) {
        if ((v.prev_variant) && (v.variant_uuid == variant_uuid)) {
            res = v.prev_variant
        }
    }
    return res
}