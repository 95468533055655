





















import {Component, Vue, Prop} from "vue-property-decorator";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {
    CorrectData
} from '../CorectHelper'

@Component({
    components: {
        // 'c-add-modal': CClarifyAddModal,
        // 'b-icon': BIcon,
        // 'b-icon-check-circle': BIconCheckCircle,
        // 'left-icon': BIconArrowLeftSquare,
        // 'right-icon': BIconArrowRightSquare,
        // 'save-icon': BIconServer,
        // 'loading': VueElementLoading
    }
})

export default class TreeEbkDiv extends Vue {
    @Prop({
        required: true,
        default: null
    })
    correct_data!: CorrectData;
    @Prop({
        required: true,
        default: null
    })
    data!: any;
    @Prop({
        required: true,
        default: "prefix"
    })
    code_prefix!: any;

}
